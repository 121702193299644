












































import Base from '@/mixins/Base.vue';
import { ISurvey, ISurveyResponse } from '@/interfaces/survey';

const component = Base.extend({
    data() {
        return {
            active: false,

            confirmation: '',
            survey: undefined as any | ISurvey,
        };
    },
    computed: {
        validated() : boolean {
            return this.confirmation === this.survey.title;
        },
    },
    mounted() {
        this.active = true;
        this.getSurvey();
    },
    methods: {
        getSurvey(): void {
            this.get<ISurveyResponse>(`surveys/${this.$route.params.id}`)
                .then(({ data }) => {
                    this.survey = data.data;
                });
        },
        save(): void {
            if (this.validated) {
                this.delete(`surveys/${this.survey.id}`).then(() => {
                    this.$router.push({ name: 'surveys.list' });
                    this.$emit('reload');
                });
            }
        },
    },
});

export default component;
